import React from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundCarpentry";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col,
} from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Siding = () => {
    const data = useStaticQuery(graphql`
    query {
      wideDivider1: file(relativePath: { eq: "new2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img1: file(relativePath: { eq: "carpentry1.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img2: file(relativePath: { eq: "carpentry2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      img3: file(relativePath: { eq: "carpentry3.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      
      paragraph1: file(relativePath: { eq: "new2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      paragraph2: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const description = 'Whatever carpentry project you have in mind, we can help you get it done.'
 return (
  <Layout>
    <SEO title="Carpentry Contractor" path='carpentry-contractor' description={description}/>
    <StyledBackgroundSection/>
    <AboveFold
      pretitle='General '
      title='Carpentry'
      >
    </AboveFold>
    
    <Hr thick/>
    
    <main id='main'>
      <div id='carpentry' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <p>
                  {description} See our work and reviews, then contact us for a quote today!
                </p>
              </Col>
            </Row>
            <Row>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img1.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
              </Col>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img2.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
              </Col>
              <Col md>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.img3.childImageSharp.fluid} className="cg-picture" alt="construction photo"/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/portfolio-carpentry'>More Photos</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
      <div id='guide' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col sm>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='General Carpentry' name='Tim C.' thumbtack>
                    We had them tear down a existing wall and replace it with a new one they did a beautiful job and very efficient and on time I would recommend MLB CONTRACTING to anyone my rating is 5 stars
                  </Review>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='Excellent skills' name='Stanley K.' thumbtack>
                    He has excellent carpentry skills. His work is neat and clean and he can tackle just about any job that you assign to him.
                  </Review>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
      <div id='paragraph' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we want you to feel good about your house. 
                  Since we have served around Oneida county for {new Date().getFullYear() - 2005} years
                  you can be confident that we have the experience 
                  and <Link to="/reviews#main" className='cg-linkDark'>track record</Link> to help you get 
                  your carpentry project done well, on time, and on budget. 
                  It easy: 
                  1.Get a quote 2.We'll contact you to discuss details 3.We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  So you can stop dreaming about your project and instead get it&nbsp;done!
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
                {/*<Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'  style={{maxWidth:200}}/>*/}
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{maxWidth:200, marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Button variant='secondary' href='/reviews#main'>Reviews</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid}/>
      
    </main>
    
    <ContactForm/> 
    
        
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All photos are from MLB Contracting projects.</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    
  </Layout>
)};


export default Siding;
